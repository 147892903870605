<!-- 28-2-9 -->
<template>
  <div v-show="!isLoading">
    <st-box v-if="reason" class="mt-24 relative">
      <safe-html :html="reason" />
    </st-box>
    <st-box v-else class="mt-24 relative text-center">
      {{ $t('studio.member_manage.list_no_data') }}
    </st-box>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useRoute } from 'vue-router';

import { definePageMeta } from '@/.nuxt/imports';
import { fetchUsageRatingHistoryApi, getDetailProductRatingApi } from '@/apis/rating.api';
import SafeHtml from '@/components/common/safe-html.vue';
import StBox from '@/components/common/st-box.vue';
import { VERIFY_STATUS } from '@/constants/products.const';
import { PRODUCT_RATING_URL } from '@/constants/url.const';
import type { IRatingHistoryItem } from '@/types/rating/rating.response.type';
import { redirectTo } from '@/utils/common.util';

definePageMeta({
  middleware: 'handle-product-data-middleware',
  pageTitle: 'studio.prj_prod_mngmt.rating_build_review.self_review.rejected_reason_pg_title'
});

const route = useRoute();

const productNo = route.params.productId as string;
const rejectId = route.query.id as string;

const reason = ref<string>('');
const isLoading = ref<boolean>(true);

const init = async () => {
  try {
    if (rejectId) {
      const res = await fetchUsageRatingHistoryApi(productNo);
      if (res && res.histories.length > 0) {
        const rejectReason = res.histories.find((item: IRatingHistoryItem) => item['h-UUID'] === rejectId);
        if (rejectReason) {
          reason.value = rejectReason.reason;
          isLoading.value = false;

          return;
        }
      }
    } else {
      const res = await getDetailProductRatingApi(productNo);
      if (res?.requestRating?.verifyInfo?.verifyStatus === VERIFY_STATUS.REJECT) {
        reason.value = res.requestRating?.verifyInfo?.reason;
        isLoading.value = false;
        return;
      }
    }

    redirectTo(PRODUCT_RATING_URL);
  } catch (error) {
    redirectTo(PRODUCT_RATING_URL);
  }
};

init();
</script>
